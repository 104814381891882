import React from "react"
import AchievementsStyles from "./achievements.module.scss"

const AchievementsSplash = () => (
  <>
    <div className={AchievementsStyles.splash}>
      <div className={AchievementsStyles.aligner}>
        <h1>Achievements</h1>
        <h2>Bloop</h2>
        <h2>Bloop</h2>
        <h2>Bloop</h2>
        <h2>Bloop</h2>
      </div>
    </div>
  </>
)

export default AchievementsSplash
